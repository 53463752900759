<template>
  <v-card
    elevation="0"
    outlined
    :style="
      isCreateMode
        ? 'border: 2px solid red;min-width:400px'
        : isEditMode
        ? 'border: 2px solid blue;min-width:400px'
        : 'border: 2px solid black;min-width:400px'
    "
  >
    <v-card-title>
      <div class="title-h6">{{ title }}</div>
      <v-row dense>
        <v-spacer />
        <v-col class="d-flex justify-end">
          <v-btn
            small
            v-if="isEditMode || isViewMode"
            color="error"
            @click.stop="newForm"
            >신규 접수 등록</v-btn
          >
          <v-btn
            class="ml-2"
            v-if="isViewMode"
            small
            color="warning"
            @click.stop="edit"
            >수정</v-btn
          >
          <v-btn
            class="ml-2"
            v-if="isEditMode"
            small
            color="info"
            @click.stop="cancelEdit"
            >취소</v-btn
          >
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text>
      <v-row dense>
        <!-- 고객사 -->
        <v-col cols="12" sm="2">고객사</v-col>
        <v-col cols="12" sm="6">
          <v-select
            dense
            v-model="item.custCode"
            :items="isCreateMode ? manualCustomers : customers"
            item-text="custName"
            item-value="custCode"
            :readonly="!isCreateMode"
          />
        </v-col>
        <v-col v-if="item.custCode === 'SCAR01'" dense cols="12" sm="4">
          <v-text-field
            dense
            v-model="item.mtRceptNo"
            :readonly="isViewMode"
            label="접수 번호"
          />
        </v-col>
        <v-col v-else-if="item.custCode !== 'SCAR01'" dense cols="12" sm="4">
          <v-select
            dense
            v-model="item.vehicleCls"
            :items="vehicleClsOpts"
            :readonly="isViewMode"
          />
        </v-col>
      </v-row>
      <v-row dense>
        <!-- 접수 일시 -->
        <v-col cols="12" sm="2">접수 일시</v-col>
        <v-col cols="12" sm="4">
          <v-text-field v-model="item.ersDts" dense :readonly="isViewMode" />
        </v-col>
        <v-col style="padding-left: 6%; padding-top: 2%" cols="12" sm="3">
          <input name="전용/고속" type="checkbox" v-model="item.isHighway" />
          <span>전용/고속{{ item.isHighway ? "O" : "X" }}</span>
        </v-col>
        <v-col v-if="item.custCode !== 'SCAR01'" dense cols="3" sm="2">
          <v-select
            v-model="item.payCode"
            :items="vs.payCodeOptions"
            dense
            :readonly="isViewMode"
            label="유/무상"
          />
        </v-col>
      </v-row>
      <v-row dense>
        <!-- 차종 -->
        <v-col cols="12" sm="2">차량정보</v-col>
        <v-col cols="12" sm="5">
          <v-text-field
            v-model="item.vehicleKind"
            dense
            :readonly="isViewMode"
            label="차종"
          />
        </v-col>
        <v-col cols="12" sm="5">
          <v-text-field
            v-model="item.plateNo"
            dense
            :readonly="isViewMode"
            label="차번호"
          />
        </v-col>
      </v-row>
      <v-row dense>
        <!-- 고객 -->
        <v-col cols="12" sm="2"> 고객정보 </v-col>
        <v-col cols="12" sm="5">
          <v-text-field
            v-model="item.driverName"
            dense
            :readonly="isViewMode"
            label="고객명"
          />
        </v-col>
        <v-col cols="12" sm="5">
          <v-text-field
            v-model="item.driverPhone"
            dense
            :readonly="isViewMode"
            label="고객 연락처"
          />
        </v-col>
      </v-row>
      <v-row dense
        ><!-- 위치 -->
        <v-col cols="12" sm="2">위치</v-col>
        <v-col cols="12" sm="5">
          <v-text-field v-model="item.addrBase" dense readonly />
          <v-text-field
            v-model="item.addrZno"
            dense
            style="width: 50%"
            label="요청위치 우편번호"
          />
        </v-col>
        <v-col cols="12" sm="5"
          ><v-text-field
            v-model="item.addrDetail"
            label="상세주소"
            dense
            :readonly="isViewMode"
        /></v-col>
      </v-row>
      <v-row v-if="!isViewMode" dense>
        <v-col cols="12" sm="2">위치 검색</v-col>
        <v-col><v-text-field v-model="addrSearch" dense /></v-col>
        <v-col cols="auto">
          <v-btn
            small
            :disabled="addrSearch.length === 0"
            width="60"
            color="primary"
            @click="searchAddrByWord"
            >주소</v-btn
          >
          <v-btn
            v-if="item.custCode !== 'SCAR01'"
            small
            :disabled="addrSearch.length === 0"
            width="60"
            color="info"
            class="ml-2"
            @click="searchAddrByPlace"
            >장소</v-btn
          >
        </v-col>
      </v-row>
      <v-row v-if="!isViewMode" dense>
        <v-col cols="12" sm="12">
          <v-data-table
            dense
            :items="addrItems"
            :headers="addrHeaders"
            :loading="loading"
            :items-per-page="5"
            item-key="no"
            mobile-breakpoint="0"
            :footer-props="vs.footerPropsItem5"
            @click:row="selectAddr"
          />
        </v-col>
      </v-row>
      <v-row
        v-if="
          item.custCode === 'SCAR01' &&
          (item.reqCode === '28' || item.reqCode === '29')
        "
        dense
        ><!--견인지 위치 -->
        <v-col cols="4" sm="2" dense>견인지 위치</v-col>
        <v-col cols="4" sm="5" :class="widt">
          <v-text-field v-model="item.towAddrBase" readonly dense />
          <v-text-field
            v-model="item.destZno"
            dense
            style="width: 50%"
            label="견인지 우편번호"
          />
        </v-col>
        <v-col cols="6" sm="5"
          ><v-text-field
            label="상세주소"
            v-model="item.towAddrDetail"
            dense
            :readonly="isViewMode"
        /></v-col>
      </v-row>
      <v-row
        dense
        v-if="
          item.custCode === 'SCAR01' &&
          (item.reqCode === '28' || item.reqCode === '29')
        "
      >
        <v-col cols="12" sm="2">견인 위치 검색</v-col>
        <v-col><v-text-field v-model="towAddrSearch" dense /></v-col>
        <v-col cols="auto">
          <v-btn
            small
            :disabled="towAddrSearch.length === 0"
            width="60"
            color="primary"
            @click="searchTowAddrByWord"
            >주소</v-btn
          >
        </v-col>
      </v-row>
      <v-row
        v-if="
          !isViewMode &&
          item.custCode === 'SCAR01' &&
          (item.reqCode === '28' || item.reqCode === '29')
        "
        dense
      >
        <v-col cols="12" sm="12">
          <v-data-table
            dense
            :items="towAddritems"
            :headers="addrHeaders"
            :loading="towLoading"
            :items-per-page="5"
            item-key="no"
            mobile-breakpoint="0"
            :footer-props="vs.footerPropsItem5"
            @click:row="selectTowAddr"
          />
        </v-col>
      </v-row>
      <v-row dense>
        <!-- 요청 -->
        <v-col cols="12" sm="2">요청내용</v-col>
        <v-col cols="12" sm="4">
          <v-select
            v-model="item.reqCode"
            dense
            :readonly="isViewMode"
            :items="
              item.custCode === 'SCAR01' ? vs.socarRequestCode : vs.requestCode
            "
            item-text="text"
            item-value="code"
          />
        </v-col>
        <v-col v-if="item.custCode !== 'SCAR01'" cols="12" sm="6">
          <v-text-field
            ref="reqMessage"
            v-model="item.reqMessage"
            dense
            :readonly="isViewMode"
          />
        </v-col>
      </v-row>
      <v-row
        dense
        v-if="item.reqCode === '14' && item.custCode !== 'SCAR01'"
        class="ml-1 mr-1"
      >
        <!-- 파트너 검색 -->
        <v-col>
          <v-row dense>
            <v-col
              ><v-text-field
                dense
                v-model="partner.search"
                @keydown.enter="searchPartner"
                label="입고지 / 협력점 검색"
              ></v-text-field
            ></v-col>
            <v-col cols="auto"
              ><v-btn small color="info" @click.stop="searchPartner"
                >검색</v-btn
              ></v-col
            >
          </v-row>
          <v-row dense>
            <v-col>
              <v-data-table
                dense
                :items="partner.items"
                :headers="partner.headers"
                :loading="partner.loading"
                item-key="_id"
                mobile-breakpoint="0"
                :items-per-page="5"
                :footer-props="vs.footerPropsItem5"
                @click:row="selectPartner"
              >
                <template v-slot:item.distKm="{ item }">
                  {{ item.distKm.toFixed(1) }}
                </template>
                <template v-slot:item.addr="{ item }">
                  {{ item.addrBase }} {{ item.addrDetail }}
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row dense>
        <!-- 전달 사항 -->
        <v-col cols="12" sm="2">전달사항</v-col>
        <v-col cols="12" sm="10"
          ><v-text-field dense v-model="item.comment" :readonly="isViewMode"
        /></v-col>
      </v-row>
      <v-row v-if="item.taskStatus === 'AA' || item.taskStatus === 'AD'" dense>
        <v-col cols="12" sm="2">출동 현황</v-col>
        <v-col cols="12" sm="10">
          {{ renderTask(item) }}
        </v-col>
      </v-row>
      <v-row v-if="item.custCode === 'SCAR01'" dense>
        <!-- 전달 사항 -->
        <v-col cols="12" sm="2">smartTokenKey</v-col>
        <v-col cols="12" sm="10"
          ><v-text-field
            dense
            v-model="item.smartTokenKey"
            :readonly="isViewMode"
        /></v-col>
      </v-row>
      <v-row dense v-if="!isCreateMode">
        <v-col cols="">
          <v-select
            dense
            v-model="item.cancelReason"
            :items="cancelReasons"
            label="취소사유 선택"
            item-text="text"
            item-value="code"
          />
        </v-col>
        <v-col cols="">
          <v-text-field
            dense
            v-model="cancelReason"
            :label="item.cancelReason"
          />
          <!--          <v-text-field dense v-show="item.isCanceled" :value="item.cancelReason" readonly />-->
        </v-col>
        <v-col cols="auto" class="mr-1">
          <v-btn
            small
            color="error"
            :disabled="!item.cancelReason || !item._id"
            @click.stop="cancelApply"
            >접수 불가 취소</v-btn
          >
        </v-col>
      </v-row>
      <v-row dense v-if="item.hasAgentReq && item.agentReq">
        <v-col>
          <span style="color: red"
            >* 가맹점 요청 : [{{
              moment(item.agentReq.createdAt).format("MM/DD HH:mm")
            }}]</span
          >
          <span style="color: red" v-if="item.agentReq.argType"
            >[{{ item.agentReq.arqType }}]</span
          >
          <span style="color: red">{{ item.agentReq.message }}</span>
        </v-col>
        <v-col cols="auto"
          ><v-btn
            small
            color="info"
            :disabled="!item.agentReq"
            @click.stop="reqDone"
            >처리완료</v-btn
          ></v-col
        >
      </v-row>
      <v-row dense>
        <v-spacer />
        <v-col cols="auto"
          ><v-btn
            class="ml-2"
            v-if="isCreateMode"
            small
            color="primary"
            @click.stop="create"
            :disabled="isCreating"
            >등록</v-btn
          ></v-col
        >
        <v-btn
          class="ml-2"
          v-if="isEditMode"
          small
          color="primary"
          @click.stop="update"
          >저장</v-btn
        >
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import vs from "@/utils/viewSupport";
import moment from "moment";
import api from "@/services/api";
import notify from "@/utils/notify";

export default {
  name: "ErsDetail",
  props: {
    ersItem: {
      type: Object,
      default: () => {
        return {};
      },
    },
    customers: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {
      vs,
      moment,
      title: "신규 등록",
      loading: false,
      towLoading: false,
      item: {
        isHighway: false,
      },
      mode: "C", // 'C' - 신규 접수 모드, 'V' - View Mode, 'E' - 수정 모드
      addrSearch: "", // 주소 검색 필드
      towAddrSearch: "", // 견인 주소 검색 필드
      addrItems: [],
      towAddritems: [],
      addrHeaders: [
        { text: "주소", value: "address_name" },
        { text: "장소", value: "place_name" },
      ],
      cancelReason: "",
      partner: {
        search: null, // 파트너 검색 필드
        items: [],
        loading: false,
        headers: [
          { text: "거리", value: "distKm" },
          { text: "이름", value: "name" },
          { text: "주소", value: "addr" },
          { text: "연락처", value: "phoneNo" },
        ],
      },
      cancelReasons: [
        "중복 접수",
        "고객사 요청 취소",
        "고객 요청 취소",
        "서비스 상이 재접수",
        "즉시 출동 불가",
        "고객 위치 상이",
        "기타",
      ],
      vehicleClsOpts: [
        { value: "10", text: "소형(승용차 등_2.5톤 미만)" },
        { value: "11", text: "중형(탑차 등_2.5톤 미만)" },
        { value: "20", text: "대형(2.5~4톤 이하)" },
        { value: "30", text: "대형(4~8톤)" },
        { value: "40", text: "대형(8톤 초과)" },
      ],
      isCreating: false,
    };
  },
  methods: {
    newForm() {
      this.$emit("newForm");
      this.readOnly = false;
      this.item = {
        ersDts: moment().format("YYYYMMDD HH:mm:ss"),
        payCode: "N",
        vehicleCls: "10",
      };
      this.title = "신규 등록";
      this.mode = "C";
      this.addrSearch = "";
      this.addrItems = [];
      this.towAddritems = [];
    },
    edit() {
      this.mode = "E";
      this.$refs.reqMessage.focus();
    },
    update() {
      // save update
      api.ersRequestUpdate(this.item).then((r) => {
        notify.showMessage("수정 되었습니다.");
        this.newForm();
        this.$emit("refresh");
      });
    },
    create() {
      this.isCreating = true;
      api
        .postErsRequestByAgency(this.item)
        .then((r) => {
          notify.showMessage("등록 되었습니다");
          this.newForm();
          this.$emit("refresh");
          this.isCreating = false;
        })
        .catch((err) => {
          this.isCreating = false;
          console.log(err);
        });
    },
    cancelEdit() {
      this.mode = "V";
    },
    selectAddr(item) {
      this.item.addrBase = item.address_name;
      this.item.addrDetail = item.place_name ? item.place_name : "";
      this.item.location = item.x.toString() + "," + item.y.toString();
      this.$emit("map-center", { longitude: item.x, latitude: item.y });
      this.$forceUpdate();
    },
    selectTowAddr(item) {
      this.item.towAddrBase = item.address_name;
      this.item.towAddrDetail = item.place_name ? item.place_name : "";
      this.item.towLocation = item.x.toString() + "," + item.y.toString();
      this.$emit("map-center", { longitude: item.x, latitude: item.y });
      this.$forceUpdate();
    },
    searchAddrByWord() {
      this.loading = true;
      api
        .getAddrByAddrWord(this.addrSearch)
        .then((r) => {
          this.addrItems = r.result;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    searchAddrByPlace() {
      api
        .getAddrByPlace(this.addrSearch)
        .then((r) => {
          this.addrItems = r.result;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    searchTowAddrByWord() {
      this.towLoading = true;
      api
        .getAddrByAddrWord(this.towAddrSearch)
        .then((r) => {
          console.log(r);
          this.towAddritems = r.result;
        })
        .finally(() => {
          this.towLoading = false;
        });
    },
    searchTowAddrByPlace() {
      api
        .getAddrByPlace(this.towAddrSearch)
        .then((r) => {
          this.towAddritems = r.result;
        })
        .finally(() => {
          this.towLoading = false;
        });
    },
    renderTask(item) {
      if (item.taskStatus === "AA") {
        return item.opName.agency + " | 배정중";
      }
      if (item.taskStatus === "AD") {
        return (
          item.opName.agency + " | " + item.opName.agent + "출동 상태 | 위치"
        );
      }
      return "";
    },
    cancelApply() {
      if (this.item._id && this.item.cancelReason) {
        let reason = this.item.cancelReason;
        if (this.cancelReason) {
          reason = reason + "/" + this.cancelReason;
        }
        api.putErsCancel(this.item._id, reason).then(() => {
          notify.showMessage("취소 신청 되었습니다.");
          this.$emit("refresh");
          this.cancelReason = "";
        });
      }
    },
    searchPartner() {
      if (this.item.location && this.item.location.split(",").length > 1) {
        const loc = this.item.location.split(",");
        this.partner.loading = true;
        api
          .getPartnersNearby(
            Number(loc[0]),
            Number(loc[1]),
            this.item.custCode,
            this.partner.search
          )
          .then((r) => {
            this.partner.items = r.result;
          })
          .finally(() => {
            this.partner.loading = false;
          });
      }
    },
    selectPartner(partner) {
      this.item.comment =
        partner.name +
        "/" +
        partner.addrBase +
        " " +
        partner.addrDetail +
        "/" +
        partner.phoneNo;
      this.$forceUpdate();
    },
    reqDone() {
      if (this.item.agentReq) {
        api.setAgentRequestConfirmByCenter(this.item.agentReq._id).then((r) => {
          notify.showMessage("처리 되었습니다.");
        });
      }
    },
  },
  watch: {
    ersItem() {
      if (this.ersItem && this.ersItem.ersId) {
        this.item = this.ersItem;
        this.title = this.item.ersId;
        this.mode = "V";
      }
    },
  },
  mounted() {
    this.newForm();
  },
  computed: {
    isCreateMode() {
      return this.mode === "C";
    },
    isViewMode() {
      return this.mode === "V";
    },
    isEditMode() {
      return this.mode === "E";
    },
    manualCustomers() {
      return this.customers.filter((e) => e.recvType === "manual");
    },
  },
};
</script>

<style scoped>
.sub-title {
  font-size: 1.1em !important;
  font-weight: bold !important;
}
.title {
  font-weight: bold;
}
.line {
  background-color: greenyellow;
  width: 60%;
  height: 50%;
}
.hidden {
  height: 50%;
  width: 60%;
}
</style>
